import Vue from 'vue'
import App from './App.vue'
import Routes from './routes'

import './assets/css/tailwind.css';

new Vue({
  render: h => h(App),
  router: Routes
}).$mount('#app')


var h = document.documentElement,
b = document.body,
st = 'scrollTop',
sh = 'scrollHeight',
progress = document.querySelector('#progress'),
scroll;
  document.addEventListener('scroll', function () {
  /* Refresh scroll % width */
  scroll = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  progress.style.setProperty('--scroll', scroll + '%');
});