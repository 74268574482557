export default {
	'/cementos-materiales-michoacan': {
		distribuidora: 'CEMENTOS Y MATERIALES DE MICHOACAN, S.A. DE C.V.',
		direccion: 'CARRETERA ZAMORA-LA BARCA KM.6 NEXT. S/N, COL.S/ COLONIA, MICHOACÁN DE OCAMPO, MÉXICO',
		telefono: '445454'
	},
	'/distribuidora-cruz-azul-bajio': {
		distribuidora: 'DISTRIBUIDORA CRUZ AZUL DEL BAJIO S.A. DE C.V.',
		direccion: 'BLVD. HERMANOS ALDAMA NEXT. 4602, COL., GUANAJUATO, MÉXICO',
		telefono: '477 764 5454'
	},
	'/distribuidora-cruz-azul-irapuato': {
		distribuidora: 'DISTRIBUIDORA CRUZ AZUL DE IRAPUATO S.A. DE C.V.',
		direccion: 'CARRETERA QUERETARO-LEON KM4.5 NEXT. S/N, COL.EJIDO LAS MALVAS, GUANAJUATO, MÉXICO',
		telefono: '462 635 1800'
	},
	'/distribuidora-cruz-azul-guanajuato': {
		distribuidora: 'DISTRIBUIDORA CRUZ AZUL DE GUANAJUATO, S.A. DE C.V.',
		direccion: 'CARRETERA JUVENTINO ROSAS KM 6.5 NEXT. S/N, COL.ARROYO VERDE, GUANAJUATO, MÉXICO',
		telefono: '473 733 0738'
	},
	'/distribuidora-cruz-azul-guadalajara': {
		distribuidora: 'DISTRIBUIDORA CRUZ AZUL DE GUADALAJARA, S.A DE C.V.',
		direccion: 'AVENIDA DEL ACERO NEXT. 14, COL.PARQUE INDUSTRIAL SIMEG, JALISCO, MÉXICO',
		telefono: '395 725 2087'
	},
	'/corporativo-cruz-azul': {
		distribuidora: 'CORPORATIVO CRUZ AZUL S.A DE C.V.',
		direccion: 'CARRETERA TOLUCA-ATLACOMULCO KM.2 NEXT. S/N, COL.SAN JUAN DE LA CRUZ, MÉXICO, MÉXICO',
		telefono: ' '
	},
	'/azul-cementos-concretos-construcciones': {
		distribuidora: 'AZUL CEMENTOS CONCRETOS Y CONSTRUCCIONES, S.A. DE C.V.',
		direccion: 'CALLE ENTRADA MATA DE PITA NEXT. S/N, COL.LAS BAJADAS, VERACRUZ DE IGNACIO DE LA LLAVE, MÉXICO',
		telefono: '331 660 5131'
	},
	'/grupo-cruz-azul-valladolid': {
		distribuidora: 'GRUPO CRUZ AZUL DE VALLADOLID SA DE CV.',
		direccion: 'CARRETERA  URUAPAN -  KM 6.5 NEXT. S/N, COL.LA BASILIA, MICHOACÁN DE OCAMPO, MÉXICO',
		telefono: '452 203 9379'
	},
	'/micro-molienda': {
		distribuidora: 'MICRO MOLIENDA S.A DE C.V',
		direccion: 'CARR. KM 4.5 CARRETERA TEPATEPEC EL MENDOZA NO. S/N NEXT. S/N, COL.EL MENDOZA, HIDALGO, MÉXICO',
		telefono: '585 544 5681'
	},
	'/corporación-azul-aceros': {
		distribuidora: 'CORPORACIÓN AZUL ACEROS  S.A DE C.V',
		direccion: 'CARRETERA TOLUCA-ATLACOMULCO KM.2 NEXT. S/N, COL.SAN JUAN DE LA CRUZ, MÉXICO, MÉXICO',
		telefono: '443 113 8400'
	},
	'/ventinno': {
		distribuidora: 'VENTINNO S.A. DE C.V.',
		direccion: '8 DE MAYO NEXT. 120 NINT. 1er piso, COL.LOMAS DE HIDALGO, MICHOACÁN DE OCAMPO, MÉXICO',
		telefono: '443 113 8400'
	},
	'/grupo-azul-mada': {
		distribuidora: 'GRUPO AZUL MADA, S.A. DE C.V.',
		direccion: '8 DE MAYO NEXT. 120, COL.LOMAS DE HIDALGO, MICHOACÁN DE OCAMPO, MÉXICO',
		telefono: ' '
	},
}