<template>
	<div class="font-sans leading-normal tracking-normal">
		<nav id="header" class="fixed w-full z-10 top-0">
			<div id="progress" class="h-1 z-20 top-0" style="background: linear-gradient( to right, #2b6cb0 var(--scroll), transparent 0);"></div>
		</nav>

    <!--Container-->
    <div class="container w-full md:max-w-3xl mx-auto">
      <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal" style="font-family: Georgia, serif">
        <!--Title-->
        <div class="font-sans text-center">
          <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
            Aviso de privacidad
          </h1>
          <p class="text-sm md:text-base font-normal text-gray-600">
            Publicado 30 Octubre 2020
          </p>
        </div>

        <!--Post Content-->

        <!--Lead Para-->
        <p class="py-6 text-justify">
					<span class="text-blue-700">{{ distribuidora }}</span>, con domicilio en <span class="text-blue-700">{{ direccion }}</span>, y portal de internet <span class="text-blue-700">{{ url }}</span>, 
					es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
        </p>

        <p class="py-6 text-justify">
					Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita: 
					fines comerciales, fines fiscales y/o fines laborales.
        </p>

        <p class="py-6 text-justify">
					Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales: 
					Nombre, Registro Federal de Contribuyentes (RFC), Domicilio, Teléfono particular, Teléfono celular, Correo electrónico, Puesto o cargo que desempeña, 
					Domicilio de trabajo, Correo electrónico institucional, Teléfono institucional, Referencias laborales, Información generada durante los procesos de reclutamiento, 
					selección y contratación, Capacitación laboral, Trayectoria educativa, Títulos, Cédula profesional, Certificados, Reconocimientos.
        </p>

        <p class="py-6 text-justify">
					Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). 
					Asimismo, es su derecho solicitar la corrección,  revocación o divulgación  de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); 
					que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); 
					así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
        </p>

        <p class="py-6 text-justify">
					Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:  El titular de los datos personales, debidamente acreditado, 
					en forma personal y/o a través de su representante legal, podrá ejercer los derechos de acceso, rectificación, cancelación y oposición con respecto al contenido, resguardo y manejo de los datos personales
					requeridos, ejerciendo los derechos que le confiere la Ley, mediante una solicitud por escrito en el domicilio del responsable de los datos.
        </p>

        <p class="py-6 text-justify">
					Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente: Por escrito, acreditando debidamente el carácter con el que se ostenta, 
					la solicitud deberá contener el nombre, domicilio, teléfono y dirección de correo electrónico, a la que se le dará respuesta en un plazo máximo de 10 días, por correo electrónico.
        </p>

        <p class="py-6 text-justify">
					Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:
					<span class="text-blue-700">a) Nombre de la persona o departamento de datos personales: {{ distribuidora }}, b) Domicilio: {{ direccion }}, c) Correo electrónico: contabilidad.cementos@corporacionazul.com.mx
					y d) Número telefónico: {{ telefono }}.</span>
        </p>

        <p class="py-6 text-justify">
					Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. 
					Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concl uir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. 
					Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
        </p>

        <p class="py-6 text-justify">
					Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. 
					Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concl uir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. 
					Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
        </p>

        <p class="py-6 text-justify">
					El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; 
					de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. 
					Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: <span class="text-blue-700">{{ url }}</span>. 
        </p>

        <p class="py-6 text-justify">
					<span class="text-blue-700">{{ distribuidora }}</span>, se reserva el derecho de cambiar, modificar, complementar y/o alterar el presente aviso, en cualquier momento, 
					en cuyo caso se hará de su conocimiento a través de cualquiera de los medios que establece la legislación en la materia.
        </p>

        <!--/ Post Content-->
      </div>
			
      <!--/Next & Prev Links-->
    </div>
    <!--/container-->

    <footer class="bg-white border-t border-gray-400 shadow bg-gray-100 ">
      <div class="container max-w-4xl mx-auto flex py-8">
        <div class="w-full mx-auto flex flex-wrap">
          <div class="flex w-full">
            <div class="px-8">
              <h3 class="font-bold text-gray-900">Nosotros</h3>
              <p class="py-4 text-gray-600 text-sm">
                Somos una empresa comprometida con la satisfacción de nuestros clientes, mediante la distribución de productos en el ramo de la construcción.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>		
	</div>
</template>

<script>
	import distribuidoras from '../distribuidoras.js'
  export default {
		name: 'AvisoPrivacidad',
		data(){
			return {
				distribuidora: '',
				direccion: '',
				telefono: '',
				url: '',
				distribuidoras
			}
		},
		mounted(){
			this.url = document.URL
			this.distribuidora = this.distribuidoras[this.$route.fullPath].distribuidora
			this.direccion = this.distribuidoras[this.$route.fullPath].direccion
			this.telefono = this.distribuidoras[this.$route.fullPath].telefono

			var h = document.documentElement,
			b = document.body,
			st = 'scrollTop',
			sh = 'scrollHeight',
			progress = document.querySelector('#progress'),
			scroll;
				document.addEventListener('scroll', function () {
				scroll = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
				progress.style.setProperty('--scroll', scroll + '%');
			});
		}
  }
</script>