<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    mounted(){
			document.title = 'Aviso de privacidad'
    }
  }
</script>

<style >
</style>
