import Vue from 'vue'
import VueRouter from 'vue-router'

import AvisoPrivacidad from './components/AvisoPrivacidad'
import NotFound from './components/404'

Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/cementos-materiales-michoacan',
			component: AvisoPrivacidad
		},
		{
			path: '/distribuidora-cruz-azul-bajio',
			component: AvisoPrivacidad
		},
		{
			path: '/distribuidora-cruz-azul-irapuato',
			component: AvisoPrivacidad
		},
		{
			path: '/distribuidora-cruz-azul-guanajuato',
			component: AvisoPrivacidad
		},
		{
			path: '/distribuidora-cruz-azul-guadalajara',
			component: AvisoPrivacidad
		},
		{
			path: '/corporativo-cruz-azul',
			component: AvisoPrivacidad
		},
		{
			path: '/azul-cementos-concretos-construcciones',
			component: AvisoPrivacidad
		},
		{
			path: '/grupo-cruz-azul-valladolid',
			component: AvisoPrivacidad
		},
		{
			path: '/micro-molienda',
			component: AvisoPrivacidad
		},
		{
			path: '/corporación-azul-aceros',
			component: AvisoPrivacidad
		},
		{
			path: '/ventinno',
			component: AvisoPrivacidad
		},
		{
			path: '/grupo-azul-mada',
			component: AvisoPrivacidad
		},
		{
			path: '*',
			component: NotFound
		},
	]
})