<template>
  <div class="font-sans leading-normal tracking-normal">
		<nav id="header" class="fixed w-full z-10 top-0">
			<div id="progress" class="h-1 z-20 top-0" style="background: linear-gradient( to right, #2b6cb0 var(--scroll), transparent 0);"></div>
		</nav>
    <!--Container-->
    <div class="container w-full md:max-w-3xl mx-auto">
      <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal" style="font-family: Georgia, serif">
        <!--Title-->
        <div class="font-sans text-center">
           <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
            Avisos de privacidad
          </h1>
          <p class="text-sm md:text-base font-normal text-gray-600">
            Publicado 30 Octubre 2020
          </p>
        </div>

        <!--Post Content-->
        <ul class="list-disc">
          <li v-for="(distribuidora, key) in distribuidoras" :key="key">
						<router-link :to="key" v-slot="{ navigate }">
							<h6 class="cursor-pointer py-2 font-sans" @click="navigate">{{ distribuidora.distribuidora }}</h6>
						</router-link>
          </li>
        </ul>

        <!--/ Post Content-->
      </div>
    </div>
    <!--/container-->
  </div>
</template>

<script>
  import distribuidoras from './../distribuidoras.js'
	export default {
		data(){
			return {
				distribuidoras
			}
		}
	}
</script>